.DropZone {
  margin-bottom: 24px;

  .validation-messages {
    transition-duration: 0.15s;
    display: inline-block;
    transform: translateY(20px);
    color: var(--color-error);
    opacity: 0;

    &.active {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  label {
    display: block;
    padding-bottom: 5px;
    padding-top: 3px;
    font-size: 1.2rem;
    font-weight: 500;
  }
  .deleteImage {
    float: right;
    transform: translateY(170%);
    margin-right: 15px;
    font-size: 1.5rem;
    cursor: pointer;
    color: #f04c4c;
  }
  .dropzone-wrapper {
    width: 100%;
    background-position: center center;
    background-origin: content-box;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    border-radius: 4px;
    border: 1px solid var(--color-border-light);
    padding: 0.325rem;
    background: var(--color-background);
    position: relative;
    overflow: hidden;
  }
  .dropzone-wrapper .deleteImage {
    transition-duration: 0.3s;
    position: absolute;
    top: 10px;
    right: -15px;
    transform: none;
    opacity: 0;
  }
  .dropzone-wrapper:hover .deleteImage {
    right: -5px;
    opacity: 1;
  }
  .dropzone-wrapper .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    border: dashed 2px var(--color-border-light);
    width: 100%;
    height: 100%;
    min-height: 150px;
    border-radius: 3px;
  }
  .dropzone-wrapper .dropzone:after {
    content: "";
    display: block;
  }
  .dropzone-wrapper .dropzone .placeholder {
    pointer-events: none;
    opacity: 0.3;
  }
  .dropzone-wrapper .dropzone svg {
    pointer-events: none;
  }
  .temp-img {
    position: absolute;
    background-size: contain;
    height: calc(100% - 16px);
    width: calc(100% - 16px);
    margin: 8px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .dropzone-wrapper .dropzone .fileInput {
    // display: none;
    border: 1px solid red;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .dropzone-wrapper .highlight {
    background-color: var(--color-border-light);
    animation: moveDash 0.25s;
    border: dashed 5px var(--color-border-light);
    opacity: 0.5;
  }
  .dropzone-wrapper .highlight:after {
    transition-duration: 0.25s;
    content: "";
    display: block;
  }
  @keyframes moveDash {
    from {
      border: dashed 0.65px var(--color-border-light);
    }
    to {
      border: dashed 5.1px var(--color-border-light);
    }
  }
}

.theme-dark {
  .DropZone {
    .placeholder {
      filter: invert(1);
    }
  }
}
