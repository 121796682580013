.ModalWrapper {
  width: 100%;
  height: 100vh;
  z-index: 999999;
  background: var(--color-overlay);
  position: fixed;
  top: 0px;
  left: 0px;
  display: none;
  align-items: center;
  justify-content: center;

  .Modal {
    box-shadow: 0px 0px 10px var(--color-boxshadow);
    max-width: 90vw;
    max-height: 90vh;
    width: auto;
    background: var(--color-background);
    border-radius: 12px;
    padding: 32px 32px 24px 32px;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .modal-header {
      display: flex;
      border-bottom: 1px solid var(--color-border-lighter);
      padding-bottom: 12px;
      margin-bottom: 16px;
      margin-top: -4px;
      .Modal_title {
        width: 100%;
        font-size: 1.8rem;
        font-weight: 600;
      }
      .cancel-icon {
        position: relative;
        padding: 12px;
        border-radius: 4px;
        margin-top: 0px;
        margin-right: -4px;
        cursor: pointer;

        &::after,
        &::before {
          content: "";
          position: absolute;
          background: var(--color-fonts);
          width: 16px;
          height: 1.5px;
          border-radius: 3px;
          left: 50%;
          top: 50%;
        }

        &::before {
          transform: translateY(-50%) translateX(-50%) rotate(45deg);
        }
        &::after {
          transform: translateY(-50%) translateX(-50%) rotate(-45deg);
        }
      }
    }
    .modal-body {
      height: 100%;
    }
    .modal-footer-wrapper {
      margin-top: auto;
      .modal-footer {
        margin-top: 24px;
        display: flex;
        justify-content: right;
        border-top: 1px solid var(--color-border-lighter);
        .button {
          width: auto;
          margin-top: 16px;
          margin-left: 16px;
        }
      }
    }
  }

  &.--isOpen {
    display: flex;
  }
}
