.date-pickers {
  input {
    cursor: pointer;
  }
  span.react-datetime-picker__inputGroup__divider {
    &:last-child {
      opacity: 0.5;
    }
  }
}

.promo-settings-edit-projekt {
  .border-primary {
    border: 1px solid var(--color-primary);
    background: var(--color-primary-5a);
    border-radius: 8px;
  }

  .bg-gray-label {
    background: var(--color-primary-30a);
    border-radius: 4px;
  }
}
